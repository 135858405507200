import React from 'react';
import { Typography } from '@material-ui/core';
import ArrowedButton from 'components/Common/ArrowedButton/ArrowedButton';
import PlainButton from 'components/Common/PlainButton/PlainButton';
import { Auth } from 'aws-amplify';
import LandingBox from 'components/LandingHome/LandingBox';
import { handleGoToAia } from 'components/LandingHome/LandingAccountFound';

const LandingEpaymentInProcess = (): JSX.Element => {
    const title = 'Payment in Progress';
    const message =
        'Payment for your dues is already in progress. To avoid duplicate charges, please allow 3-4 days for the transaction to be completed.';
    return (
        <LandingBox title={title}>
            <Typography variant="h3" data-testid="text">
                {message}
            </Typography>

            <ArrowedButton className="action-button" text={'Go to AIA.org'} onClick={handleGoToAia} />
            <PlainButton text="Back" onClick={async (): Promise<{}> => await Auth.signOut()} />
        </LandingBox>
    );
};

export default LandingEpaymentInProcess;
