import { Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import React from 'react';
import { ContactStatus } from '../../utils/get-contact-statuses';
import ArrowedButton from '../Common/ArrowedButton/ArrowedButton';
import PlainButton from '../Common/PlainButton/PlainButton';
import LandingBox from './LandingBox';
import { membershipCondition } from '../../utils/membership-condition';

const handleSignIn = (e: React.MouseEvent, callToAction?: string, email?: string): void => {
    e.preventDefault();
    Auth.federatedSignIn({
        customProvider: 'COGNITO',
        customState: email,
    });
};

export const handleGoToAia = (): void => {
    window.location.href = 'https://aia.org';
};

const getCallToActionName = (
    contactStatus: ContactStatus,
): [(string | JSX.Element)[], string | undefined, string | undefined] => {
    const foundMessage = ['We found an AIA account for ', <strong key="email">{contactStatus.email}</strong>, '.'];
    const notFoundMessage = [
        'The account for ',
        <strong key="email">{contactStatus.email}</strong>,
        ' is already active. No action is required until the next membership renewal period.',
    ];
    const cesAuditFailedMessage = [
        'We found an AIA account for ',
        <strong key="email">{contactStatus.email}</strong>,
        ". Your record is under audit for not meeting AIA's education requirements, and are not eligible to renew till this has been corrected. Please contact member services at (800) AIA 3837.",
    ];
    const underCesAuditMessage = [
        'You are currently under AIA CES audit for failure to meet your annual continuing education requirement.  Your membership is not currently eligible for renewal.  Please contact ',
        <a key="ces-email" href="mailto:ces@aia.org">
            ces@aia.org
        </a>,
        ' for more details.',
    ];
    const deceasedMessage = ['This member is not eligible to renew because is marked as deceased.'];
    const epaymentInProgressOrPendingMessage = [
        'Payment for your dues is already in progress. To avoid duplicate charges, please allow 3-4 days for the transaction to be completed.',
    ];
    const underAuditTitle = 'Under Audit';
    const epaymentInProgressOrPendingMessageTitle = 'Payment in Progress';
    const condition = membershipCondition(
        contactStatus.status || '',
        contactStatus.cesAudit,
        contactStatus.renewYear,
        contactStatus.renewEligibleYears,
        contactStatus.epaymentStatus,
        contactStatus.deceased,
    );

    switch (condition) {
        case 'renew':
            return [foundMessage, 'Renew your membership', undefined];
        case 'restart':
            return [foundMessage, 'Restart your membership', undefined];
        case 'join':
            return [foundMessage, 'Join AIA', undefined];
        case 'ces-audit':
            return [cesAuditFailedMessage, undefined, undefined];
        case 'under-audit':
            return [underCesAuditMessage, undefined, underAuditTitle];
        case 'deceased':
            return [deceasedMessage, undefined, undefined];
        case 'epayment-in-progress-or-pending':
            return [epaymentInProgressOrPendingMessage, undefined, epaymentInProgressOrPendingMessageTitle];
        default:
            return [notFoundMessage, undefined, undefined];
    }
};

const LandingAccountFound = (props: { contactStatus: ContactStatus; onBack: () => void }): JSX.Element | null => {
    const [message, callToAction, titleName] = getCallToActionName(props.contactStatus);

    return (
        <LandingBox title={titleName || 'Account found'}>
            <Typography variant="h3" data-testid="text">
                {message}
            </Typography>

            <ArrowedButton
                className="action-button"
                text={callToAction || 'Go to AIA.org'}
                onClick={
                    callToAction
                        ? (e): void => handleSignIn(e, callToAction, props.contactStatus?.email)
                        : handleGoToAia
                }
            />
            <PlainButton text="Back" onClick={(): void => props.onBack()} />
        </LandingBox>
    );
};

export default LandingAccountFound;
