import React, { Dispatch, SetStateAction, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from './aws-exports';
import theme from './utils/theme';
import ScreensError from './screens/Error';
import { ModalContext } from './contexts/ModalContext';
import Modal from './components/Common/Modal/Modal';
import { DEFAULT_MODAL_CONTENT } from './utils/constants';
import { AppContainerStyle } from './App.style';
import { MembershipContext } from './contexts/MembershipContext';
import MembershipRoute from './components/Common/MembershipRoute/MembershipRoute';
import SSORoute from './components/Common/SSORoute/SSORoute';
import ScreensRenew from './screens/Renew';
import WithLoading from './hocs/WithLoading/WithLoading';
import ScreensJoin from './screens/Join';
import ScreensActive from './screens/Active/Active';
import { BackdropContext } from './contexts/BackdropContext';
import WithBackdropContext from './hocs/WithBackdropContext/WithBackdropContext';
import CustomBackdrop from './components/Common/CustomBackdrop/CustomBackdrop';
import ScreensHome from './screens/Home';
import AccountRecovery from './screens/AccountRecovery';
import AccountRecoveryAttempt from './screens/AccountRecoveryAttempt';
import DuesEstimator from './screens/DuesEstimator';
import MainLayout from './components/Common/MainLayout/MainLayout';
import ScreensCesAudit from './screens/CesAudit';
import ScreensUnderAudit from './screens/UnderAudit';
import { MemberOrderResponse } from 'data_types/member-order';
import { BackdropOptions } from 'data_types/backdrop-options';
import { GlobalValue } from 'data_types/global-value';
import LandingEpaymentInProcess from 'screens/LandingEpaymentInProcess';

const ScreensHomeWithLoading = WithLoading(ScreensHome);
const ScreensRenewWithLoading = WithLoading(ScreensRenew);
const ScreensJoinWithLoading = WithLoading(ScreensJoin);
const ScreensActiveWithLoading = WithLoading(ScreensActive);
const ScreensCesAuditWithLoading = WithLoading(ScreensCesAudit);
const ScreensUnderAuditWithLoading = WithLoading(ScreensUnderAudit);

const App = (): JSX.Element => {
    const [modalOptions, setModalOptions] = useState(DEFAULT_MODAL_CONTENT);
    const [backdropOptions, setBackdropOptions] = useState({ isOpen: false } as BackdropOptions);
    const [membershipInfo, setMembershipInfo] = useState((null as unknown) as MemberOrderResponse);
    const [countries, setCountries] = useState((null as unknown) as GlobalValue[]);
    const [careerTypes, setCareerTypes] = useState((null as unknown) as GlobalValue[]);
    const ModalWithContext = WithBackdropContext('modal', ModalContext, Modal);
    const BackdropWithContext = WithBackdropContext(
        'backdrop',
        BackdropContext as React.Context<{
            options: BackdropOptions;
            setOptions: Dispatch<SetStateAction<BackdropOptions>>;
        }>,
        CustomBackdrop,
    );
    const containerClass = AppContainerStyle();

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <BackdropContext.Provider value={{ options: backdropOptions, setOptions: setBackdropOptions }}>
                    <ModalContext.Provider value={{ options: modalOptions, setOptions: setModalOptions }}>
                        <MembershipContext.Provider
                            value={{
                                membershipInfo,
                                setMembershipInfo,
                                countries,
                                setCountries,
                                careerTypes,
                                setCareerTypes,
                            }}
                        >
                            <CssBaseline />
                            <MainLayout>
                                <BackdropWithContext />
                                <ModalWithContext />
                                <Container classes={{ root: containerClass.root }} maxWidth="lg">
                                    <Switch>
                                        <MembershipRoute exact path="/">
                                            <ScreensHomeWithLoading />
                                        </MembershipRoute>
                                        <MembershipRoute exact path="/epayment-in-progress-or-pending">
                                            <LandingEpaymentInProcess />
                                        </MembershipRoute>
                                        <MembershipRoute exact path="/renew">
                                            <ScreensRenewWithLoading />
                                        </MembershipRoute>
                                        <MembershipRoute exact path="/join">
                                            <ScreensJoinWithLoading />
                                        </MembershipRoute>
                                        <MembershipRoute exact path="/restart">
                                            <ScreensJoinWithLoading />
                                        </MembershipRoute>
                                        <MembershipRoute exact path="/active">
                                            <ScreensActiveWithLoading />
                                        </MembershipRoute>
                                        <MembershipRoute exact path="/ces-audit">
                                            <ScreensCesAuditWithLoading />
                                        </MembershipRoute>
                                        <MembershipRoute exact path="/under-audit">
                                            <ScreensUnderAuditWithLoading />
                                        </MembershipRoute>
                                        <Route exact path="/dues-estimator">
                                            <DuesEstimator />
                                        </Route>
                                        <Route exact path="/account-recovery">
                                            <AccountRecovery />
                                        </Route>
                                        <Route exact path="/account-recovery-attempt">
                                            <AccountRecoveryAttempt />
                                        </Route>
                                        <SSORoute exact path="/sso" />
                                        <Route>
                                            <ScreensError
                                                {...{
                                                    errorHeader: '404 Not Found',
                                                    errorMessage: 'We cannot find the page you are looking for',
                                                }}
                                            />
                                        </Route>
                                    </Switch>
                                </Container>
                            </MainLayout>
                        </MembershipContext.Provider>
                    </ModalContext.Provider>
                </BackdropContext.Provider>
            </ThemeProvider>
        </Router>
    );
};

const awsConfig = {
    ...awsExports,
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_WEBCLIENT_ID,
        mandatorySignIn: false,
        storage: sessionStorage,
    },
};

const authConfig = {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    scope: ['phone', 'email', 'profile', 'openid', process.env.REACT_APP_AUTH_MEMBERSHIP_SCOPE],
    redirectSignIn: `${window.location.protocol}//${window.location.host}`,
    redirectSignOut: `${window.location.protocol}//${window.location.host}`,
    responseType: 'code',
};

Amplify.configure(awsConfig);
Auth.configure({ oauth: authConfig });

export default App;
