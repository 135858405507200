/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, cloneElement } from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { MemberOrderResponse } from '../../../data_types/member-order';
import { GlobalValue } from '../../../data_types/global-value';
import { useMembershipInfo } from '../../../hooks/use-membership-info';
import { useEntities } from '../../../hooks/use-entities';
import useQuery from '../../../hooks/use-query';
import { Auth } from 'aws-amplify';
import { membershipCondition } from '../../../utils/membership-condition';

const redirectToHome = (user: unknown, loading: boolean, path: string): boolean => !user && !loading && path !== '/';

const redirectToPath = (
    membershipInfo: MemberOrderResponse | null,
    countries: GlobalValue[] | null,
    user: unknown,
    loading: boolean,
    path: string,
): string | null => {
    let redirectToPath = null;

    if (redirectToHome(user, loading, path)) {
        redirectToPath = '/';
    } else if (membershipInfo && countries) {
        const condition = membershipCondition(
            membershipInfo.contact.membershipStatus,
            membershipInfo.contact.cesAudit,
            membershipInfo.contact.renewYear,
            membershipInfo.renewEligibleYears,
            membershipInfo.contact.membershipEpaymentStatus,
        );
        const conditionPath = `/${condition}`;
        redirectToPath = conditionPath !== path ? conditionPath : null;
    }

    return redirectToPath;
};

const MembershipRoute: React.FC<RouteProps> = (props: RouteProps) => {
    const { children, ...otherProps } = props;
    const [loadingUser, setLoadingUser] = useState(false);
    const [user, setUser] = useState(null);
    const entities = useEntities();
    const membershipInfo = useMembershipInfo();

    useEffect(() => {
        setLoadingUser(true);
        Auth.currentAuthenticatedUser()
            .then((userData) => {
                setUser(userData);
                if (!membershipInfo.membershipInfo) {
                    membershipInfo.fetchData();
                }
                if (!(entities.countries && entities.careerTypes)) {
                    entities.fetchData();
                }
            })
            .catch(() => undefined)
            .finally(() => {
                setLoadingUser(false);
            });
    }, []);

    const loading = loadingUser || entities.loading || membershipInfo.loading;
    const error = entities.error || membershipInfo.error;

    const location = useLocation();
    const path = location.pathname;
    const redirectTo = redirectToPath(membershipInfo.membershipInfo, entities.countries, user, loading, path);
    const query = useQuery();
    if (redirectTo) {
        return (
            <Redirect
                to={{
                    pathname: redirectTo,
                    search: query.toString(),
                }}
            />
        );
    }

    return (
        <Route {...otherProps}>
            {children
                ? cloneElement(children as JSX.Element, {
                      loading,
                      error,
                      membershipInfo: membershipInfo.membershipInfo,
                      countries: entities.countries,
                      careerTypes: entities.careerTypes,
                  })
                : null}
        </Route>
    );
};

export default MembershipRoute;
