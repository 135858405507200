import { API } from 'aws-amplify';

export interface ContactStatus {
    email: string;
    status: string | null;
    legacyId: string;
    cesAudit: string | null;
    renewYear: number | null;
    renewEligibleYears: number[];
    epaymentStatus: string;
    deceased: boolean;
}

const getContactStatuses = async (email: string): Promise<ContactStatus[]> => {
    const response = await API.get('membership', `/users?email=${email}`, {});

    return response.data;
};

export default getContactStatuses;
